import { Controller } from "stimulus";
import { formatCurrency, getActiveCurrency } from "../../util/currency";
import { staticPercentChangeTemplate } from "../../template/price_templates";
import { EventCurrencyChanged, EventSelectPortfolio } from "../../events";
import { throttle } from "../../util";
import { loadFlatpickr } from "../../util/load_package";

const EXPONENT_CONFIG = { significantFigures: 4, maximumDecimalTrailingZeroes: 4 }
const NO_VALUE_ATTRIBUTE_WHITELIST = ["profit_loss", "cost", "proceeds"];

export default class extends Controller {
  static targets = ["hideable", "skeleton", "price", "signedPrice", "percent", "number", "date", "transactionModalButton"];

  connect() {
    this.isSensitiveDataHidden = false;

    this.pageType = this.element.dataset.page;
    this.portfolioId = +this.element.dataset.portfolioId;
    this.coinSlug = this.element.dataset.coinSlug;

    this.refreshTransactionValues = throttle(this._refreshTransactionValues.bind(this), 1000, true);
    this.refreshTransactionValues();

    window.addEventListener(EventCurrencyChanged, () => this.refreshTransactionValues());

    if (this.pageType === "coins") {
      this._handlePortfolioChange();
    }
  }

  _handlePortfolioChange() {
    window.addEventListener(EventSelectPortfolio, (e) => {
      const { identifier, value, portfolioCoinId } = e.detail.sourceTarget.dataset;
      const selectedPortfolioId = +value;

      if (identifier === "portfolio-transaction-dropdown") {
        if (this.portfolioId === selectedPortfolioId) {
          return;
        }

        this.portfolioId = selectedPortfolioId;
        this.transactionModalButtonTarget.dataset.portfolioCoinId = portfolioCoinId;
        this.refreshTransactionValues();
      }
    });
  }

  hideSensitiveData() {
    if (this.isSensitiveDataHidden) {
      this.isSensitiveDataHidden = false;

      this.handlePortfolioCoinValues(JSON.parse(this.element.dataset.portfolioCoinsData)[0] || "null");
      return;
    }

    this.isSensitiveDataHidden = true;
    this.hideableTargets.forEach((target) => {
      target.innerHTML = "••••••";
    })
  }

  _refreshTransactionValues() {
    this.toggleSkeleton(this.skeletonTargets, false);

    const currency = getActiveCurrency();
    const url = `/${I18n.locale}/portfolios/${this.portfolioId}/${this.coinSlug}/transaction_overview?vs_currency=${currency}`;

    fetch(url, { credentials: "same-origin" })
      .then((response) => response.json())
      .then(async (json) => {
        this.handlePortfolioCoinValues(json);
        this.handlePortfolioCoinTransactionValues(json);
        await this.handleDateLocalization();

        this.toggleSkeleton(this.skeletonTargets, true);

        if (this.isSensitiveDataHidden) {
          this.hideableTargets.forEach((target) => {
            target.innerHTML = "••••••";
          });
        }
      });
  }


  handlePortfolioCoinValues(data) {
    this.priceTargets.forEach((target) => {
      const attr = target.dataset.attr;
      const value = data[attr];

      const absoluteValue = Math.abs(value);
      const showExponent = absoluteValue > 0 && absoluteValue <= 1;

      target.innerHTML = formatCurrency(value, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
    });


    this.signedPriceTargets.forEach((target) => {
      const attr = target.dataset.attr;
      const value = data[attr];
      const suffix = value >= 0 ? "+" : "-";

      const absoluteValue = Math.abs(value);
      const showExponent = absoluteValue > 0 && absoluteValue <= 1;

      target.innerHTML = suffix + formatCurrency(absoluteValue, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
      target.classList.toggle("gecko-up", value >= 0);
      target.classList.toggle("gecko-down", value < 0);
    });


    this.percentTargets.forEach((target) => {
      let attr = target.dataset.attr;
      let value = data[attr];

      target.innerHTML = staticPercentChangeTemplate(value);
    });


    this.numberTargets.forEach((target) => {
      let attr = target.dataset.attr;
      target.innerText = data[attr];
    });

    // Set JSON on the data attribute to share with other controllers.
    this.element.dataset.portfolioCoinsData = JSON.stringify([{ ...data, transactions: undefined }]);
  }

  handlePortfolioCoinTransactionValues(data) {
    data.transactions.forEach(transaction => {
      let portfolioCoinTransactionId = transaction.id;
      let portfolioCoinTransactionRow = this.element.querySelector(`tr[data-portfolio-coin-transaction-id="${portfolioCoinTransactionId}"]`);

      if (!portfolioCoinTransactionRow) {
        return;
      }

      portfolioCoinTransactionRow.querySelectorAll("[data-attr]").forEach(target => {
        const value = transaction[target.dataset.attr];

        const absoluteValue = Math.abs(value);
        const showExponent = absoluteValue > 0 && absoluteValue <= 1;

        let suffix = "";
        if (target.dataset.attr === "profit_loss") {
          target.classList.toggle("gecko-up", value >= 0);
          target.classList.toggle("gecko-down", value < 0);

          suffix = value > 0 ? "+" : "";
        }

        if (NO_VALUE_ATTRIBUTE_WHITELIST.includes(target.dataset.attr) && value == 0) {
          target.innerHTML = "-";
        } else {
          target.innerHTML = suffix + formatCurrency(value, getActiveCurrency(), false, showExponent ? EXPONENT_CONFIG : false);
        }
      })
    });
  }

  async handleDateLocalization() {
    const Flatpickr = await loadFlatpickr();

    this.dateTargets.forEach(dateTarget => {
      const utcDateString = dateTarget.dataset.isoDate || dateTarget.innerText;
      if (!utcDateString) {
        return;
      }

      const utcDate = new Date(utcDateString);
      dateTarget.innerText = Flatpickr.formatDate(utcDate, "d M Y, h:iK")
    })
  }


  toggleSkeleton(target, finished) {
    let skeletonTargets = [].concat(target);

    skeletonTargets.forEach(skeletonTarget => {
      let loadingTarget = skeletonTarget.querySelector(".gecko-loading");
      let contentTarget = skeletonTarget.querySelector(".gecko-content");

      loadingTarget.classList.toggle("tw-hidden", finished);
      contentTarget.classList.toggle("tw-hidden", !finished);
    });
  }
}
