import { Controller } from "stimulus";
import { EventCurrencyChanged, EventPriceChanged } from "../../events";
import { displayRawNumber, getActiveCurrency } from "../../util/currency";
import { convertPrice } from "../../util/convert";

export default class extends Controller {
  static targets = ["fromInput", "toInput", "price"];

  connect() {
    if (!this.hasPriceTarget) {
      return console.error("No reference price target found");
    }

    this.updateConverterInput();

    window.addEventListener(EventCurrencyChanged, this.updateConverterInput.bind(this));
    window.addEventListener(EventPriceChanged, this.updateConverterInput.bind(this));
  }

  updateConverterInput(e) {
    if (this.inputTarget === "to") {
      this._calculate(this.toInputTarget);
    } else {
      this._calculate(this.fromInputTarget);
    }
  }

  handleConverterInput(e) {
    this._calculate(e.currentTarget);
  }

  _calculate(target) {
    const priceBTC = this.priceTarget.dataset.priceBtc;
    const conversionRate = +convertPrice(+priceBTC, getActiveCurrency(), true);
    const conversionAmount = +target.value;

    if (target === this.fromInputTarget) {
      this.inputTarget = "from";
      this.toInputTarget.value = displayRawNumber(conversionAmount * conversionRate);
    } else if (target === this.toInputTarget) {
      this.inputTarget = "to";
      this.fromInputTarget.value = displayRawNumber(conversionAmount / conversionRate);
    } else {
      return console.error("Invalid conversion target");
    }
  }
}
