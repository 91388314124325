import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["highlightsToggle", "highlights"];

  connect() {
    this.page = this.highlightsTarget.dataset.page;
  }

  toggleHighlights(e) {
    this.highlightsEnabled = this.highlightsToggleTarget.checked;
    localStorage.setItem(`${this.page}:highlights`, this.highlightsEnabled);

    this._refreshHighlights();
  }

  _refreshHighlights() {
    this.highlightsToggleTarget.checked = this.highlightsEnabled;
    this.highlightsTarget.style.transition = "max-height 0.3s";
    this.highlightsTarget.style.overflowY = "hidden";
    this.highlightsTarget.style.maxHeight = 0;

    requestAnimationFrame(() => {
      this.highlightsTarget.classList.toggle("tw-hidden", !this.highlightsEnabled);
      this.highlightsTarget.classList.toggle("!tw-hidden", !this.highlightsEnabled);
      this.highlightsTarget.style.maxHeight = highlights.scrollHeight + "px";

      setTimeout(() => { this.highlightsTarget.style.maxHeight = null; }, 300);
    });
  }
}
